import "styles/pages/page-media.scss";

import React, { useState } from "react";
import Lightbox from "react-image-lightbox";

import Layout from "components/Layout";
import Seo from "components/Seo";
import SubpageHeader from "components/SubpageHeader";
import Divider from "components/Divider";
import ZoomWrapper from "components/ZoomWrapper";
import Breadcrumps from "components/Breadcrumps";

const MediaPage = ({ pageContext }) => {
  const seo = pageContext.seo;
  const pageData = pageContext.data.pageMedia;
  const [photoIndex, setPhotoIndex] = useState(0);
  const [isLightboxOpen, setIsLightboxOpen] = useState(false);

  const gallery = pageData.galleryAll?.map(
    (item) => item.galleryItem?.sourceUrl
  );

  const galleryBackgroundImg = [];
  function createBackgroundImageVariables() {
    for (let i = 0; i <= 11; ++i) {
      galleryBackgroundImg[i] = {
        backgroundImage: `url(${pageData.galleryAll?.[i].galleryItem?.sourceUrl})`,
      };
    }
  }
  createBackgroundImageVariables();

  return (
    <Layout>
      <Seo title={seo.title} description={seo.metaDesc} />
      <Breadcrumps data={pageContext.data.title} />
      <SubpageHeader title={pageContext.data.title} />
      <section className="media-section-1">
        <div className="container">
          <div className="row">
            <div className="col-lg-6">
              <div
                className="media-section-1__text"
                dangerouslySetInnerHTML={{ __html: pageData.mediaText1 }}
              />
            </div>
            <div className="col-md-6">
              <img
                src={pageData.mediaImg1?.sourceUrl}
                alt={pageData.mediaImg1?.altText}
                className="img-fluid"
              />
            </div>
            <div className="col-md-6">
              <img
                src={pageData.mediaImg2?.sourceUrl}
                alt={pageData.mediaImg2?.altText}
                className="img-fluid"
              />
            </div>
            <div className="col-lg-6">
              <div
                className="media-section-1__text"
                dangerouslySetInnerHTML={{ __html: pageData.mediaText2 }}
              />
            </div>
          </div>
        </div>
        <br></br>
      </section>
      <section className="media-section-2">
        <div className="container">
          <h2 className="section-title">{pageData.logosTitle}</h2>
          <Divider marginTop={15} marginBottom={60} />
          <div className="row">
            {pageData.logosAll?.map((logo, index) => {
              const { sourceUrl, altText } = logo.logosItem;
              return (
                <div key={index} className="col-md-4">
                  <div className="media-section-2__logo-container">
                    <img src={sourceUrl} alt={altText} className="img-fluid" />
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </section>
      <section className="media-section-3">
        <div className="container">
          <h2 className="section-title">{pageData.galleryTitle}</h2>
          <Divider marginTop={15} marginBottom={45} />
          <div className="row justify-content-center g-0">
            <div className="col-md-6">
              <div
                className="media-section-3__img media-section-3__img--1"
                style={galleryBackgroundImg[0]}
              >
                <ZoomWrapper
                  onClick={(e) => {
                    e.preventDefault();
                    setIsLightboxOpen(true);
                    setPhotoIndex(0);
                  }}
                />
              </div>
            </div>
            <div className="col-md-6">
              <div
                className="media-section-3__img media-section-3__img--2"
                style={galleryBackgroundImg[1]}
              >
                <ZoomWrapper
                  onClick={(e) => {
                    e.preventDefault();
                    setIsLightboxOpen(true);
                    setPhotoIndex(1);
                  }}
                />
              </div>
            </div>
            <div className="col-md-4">
              <div
                className="media-section-3__img media-section-3__img--3"
                style={galleryBackgroundImg[2]}
              >
                <ZoomWrapper
                  onClick={(e) => {
                    e.preventDefault();
                    setIsLightboxOpen(true);
                    setPhotoIndex(2);
                  }}
                />
              </div>
            </div>
            <div className="col-md-4">
              <div
                className="media-section-3__img media-section-3__img--4"
                style={galleryBackgroundImg[3]}
              >
                <ZoomWrapper
                  onClick={(e) => {
                    e.preventDefault();
                    setIsLightboxOpen(true);
                    setPhotoIndex(3);
                  }}
                />
              </div>
            </div>
            <div className="col-md-4">
              <div
                className="media-section-3__img media-section-3__img--5"
                style={galleryBackgroundImg[4]}
              >
                <ZoomWrapper
                  onClick={(e) => {
                    e.preventDefault();
                    setIsLightboxOpen(true);
                    setPhotoIndex(4);
                  }}
                />
              </div>
            </div>
            <div className="col-md-6">
              <div
                className="media-section-3__img media-section-3__img--6"
                style={galleryBackgroundImg[5]}
              >
                <ZoomWrapper
                  onClick={(e) => {
                    e.preventDefault();
                    setIsLightboxOpen(true);
                    setPhotoIndex(5);
                  }}
                />
              </div>
            </div>
            <div className="col-md-6">
              <div
                className="media-section-3__img media-section-3__img--7"
                style={galleryBackgroundImg[6]}
              >
                <ZoomWrapper
                  onClick={(e) => {
                    e.preventDefault();
                    setIsLightboxOpen(true);
                    setPhotoIndex(6);
                  }}
                />
              </div>
            </div>
            <div className="col-md-4">
              <div
                className="media-section-3__img media-section-3__img--8"
                style={galleryBackgroundImg[7]}
              >
                <ZoomWrapper
                  onClick={(e) => {
                    e.preventDefault();
                    setIsLightboxOpen(true);
                    setPhotoIndex(7);
                  }}
                />
              </div>
            </div>
            <div className="col-md-4">
              <div
                className="media-section-3__img media-section-3__img--9"
                style={galleryBackgroundImg[8]}
              >
                <ZoomWrapper
                  onClick={(e) => {
                    e.preventDefault();
                    setIsLightboxOpen(true);
                    setPhotoIndex(8);
                  }}
                />
              </div>
            </div>
            <div className="col-md-4">
              <div
                className="media-section-3__img media-section-3__img--10"
                style={galleryBackgroundImg[9]}
              >
                <ZoomWrapper
                  onClick={(e) => {
                    e.preventDefault();
                    setIsLightboxOpen(true);
                    setPhotoIndex(9);
                  }}
                />
              </div>
            </div>
            <div className="col-md-6">
              <div
                className="media-section-3__img media-section-3__img--11"
                style={galleryBackgroundImg[10]}
              >
                <ZoomWrapper
                  onClick={(e) => {
                    e.preventDefault();
                    setIsLightboxOpen(true);
                    setPhotoIndex(10);
                  }}
                />
              </div>
            </div>
            <div className="col-md-6">
              <div
                className="media-section-3__img media-section-3__img--12"
                style={galleryBackgroundImg[11]}
              >
                <ZoomWrapper
                  onClick={(e) => {
                    e.preventDefault();
                    setIsLightboxOpen(true);
                    setPhotoIndex(11);
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </section>
      {isLightboxOpen && (
        <Lightbox
          mainSrc={gallery[photoIndex]}
          nextSrc={gallery[(photoIndex + 1) % gallery.length]}
          prevSrc={gallery[(photoIndex + gallery.length - 1) % gallery.length]}
          onCloseRequest={() => setIsLightboxOpen(false)}
          onMovePrevRequest={() =>
            setPhotoIndex((photoIndex + gallery.length - 1) % gallery.length)
          }
          onMoveNextRequest={() =>
            setPhotoIndex((photoIndex + 1) % gallery.length)
          }
        />
      )}
    </Layout>
  );
};

export default MediaPage;
